<!--
 * @Author: wxy
 * @Date: 2022-10-12 18:03:22
 * @LastEditTime: 2022-10-26 17:51:48
 * @Description: 简历投递管理
-->

<template>
  <a-spin :spinning="isLoading" size="large">
    <div>
      <ds-header title="简历投递管理"></ds-header>
      <a-form layout="inline" style="margin-bottom: 10px" :labelCol="{ style: 'width: 120px' }">
        <a-form-item label="招聘岗位名称">
          <a-input
            v-model.trim="searchForm.recruitName"
            placeholder="请输入招聘岗位名称"
            style="width: 196px"
          ></a-input>
        </a-form-item>
        <a-form-item label="投递人姓名">
          <a-input
            v-model.trim="searchForm.deliveryUserName"
            placeholder="请输入投递人姓名查询"
            style="width: 196px"
          ></a-input>
        </a-form-item>
        <a-form-item label="投递人手机号">
          <a-input
            v-model.trim="searchForm.deliveryUserPhone"
            placeholder="请输入投递人手机号查询"
            style="width: 196px"
          ></a-input>
        </a-form-item>
        <a-form-item label="岗位类型">
          <a-select
            style="width: 196px"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            placeholder="请选择岗位类型"
            v-model="searchForm.jobTypeId"
          >
            <a-select-option value=""> 全部岗位类型 </a-select-option>
            <a-select-option v-for="item in jobTypeList" :key="item.id" :value="item.id">
              {{ item.commonsValueName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="意向企业">
          <a-select
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode;
              }
            "
            style="width: 196px"
            show-search
            placeholder="请输入企业名称以搜索"
            :filter-option="false"
            :not-found-content="null"
            @select="selectEnterPrise"
            @search="handleSearch"
            v-model="selectData"
            @mouseenter="handleClear"
            :allowClear="true"
          >
            <a-select-option v-for="item in allEnterprise" :key="item.enterpriseId" :value="item.enterpriseId">
              {{ item.enterpriseName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="岗位所在项目">
          <!-- <a-select
            style="width: 196px"
            mode="multiple"
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            placeholder="请选择岗位所在项目"
            v-model="searchForm.projectIds"
          >
            <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
              {{ item.projectName }}
            </a-select-option>
          </a-select> -->
          <el-cascader v-model="searchForm.projectIds" style="width: 200px"
              :options="projectList"
              :collapse-tags = "true"
              :props="{emitPath:false,multiple: true,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader>
        </a-form-item>
        <a-form-item label="简历投递时间">
          <a-range-picker
            style="width: 196px"
            v-model="deliveryDate"
            format="YYYY-MM-DD"
            @change="onChangeTime"
            :disabled-date="disabledDate"
            :getCalendarContainer="
              (triggerNode) => {
                return triggerNode.parentNode;
              }
            "
          />
        </a-form-item>
        <a-form-item label="简历发送状态">
          <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            style="width: 196px"
            placeholder="请选择简历发送状态"
            v-model="searchForm.deliveryStatus"
          >
            <a-select-option value=""> 全部简历发送状态 </a-select-option>
            <a-select-option v-for="item in status" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item style="margin-left: 20px">
          <a-button type="primary" @click="search" icon="search" style="margin-top: 3px"> 查询 </a-button>
          <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
        </a-form-item>
      </a-form>
      <div style="margin: 10px 0">
        <span>
          <a-button type="primary" @click="batchDownload"> 批量下载 </a-button>
          <a-tooltip>
            <template slot="title">
              批量下载功能将下载当前筛选条件及分页条件下的所有数据，时间可能较长，请耐心等待
            </template>
            <a-icon type="question-circle" style="margin-left: 4px; color: #666666" />
          </a-tooltip>
        </span>
        <span>
          <a-button style="margin-left: 15px" type="primary" @click="exportList"> 列表导出 </a-button>
          <a-tooltip>
            <template slot="title">列表导出功能将导出所有简历投递记录，时间可能较长，请耐心等待 </template>
            <a-icon type="question-circle" style="margin-left: 4px; color: #666666" />
          </a-tooltip>
        </span>
      </div>
      <a-table
        :rowKey="(record, index) => index"
        :columns="table.columns"
        :data-source="table.tableData"
        :pagination="table.pagination.total ? table.pagination : false"
        @change="pageChange"
        bordered
        :scroll="{ x: 2200 }"
      >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button type="link" @click="sendAgain(record.id)" class="btn"> 重新发送 </a-button>
          <a-button type="link" @click="toDetail(record)" class="btn"> 查看简历 </a-button>
          <a-button type="link" @click="download(record.id)" class="btn"> 下载简历 </a-button>
        </span>
      </a-table>
    </div>
  </a-spin>
</template>

<script>
import moment from "moment";
import debounce from "lodash/debounce";
import * as api from "../../../api/socialRecruitingServicesManagement";
import { getProject } from "@/api/userMessage";
export default {
  name: "resumeSubmitManagement",
  data() {
    return {
      // 搜索条件
      searchForm: {
        // 岗位名称
        recruitName: "",
        // 投递人姓名
        deliveryUserName: "",
        // 投递人手机号
        deliveryUserPhone: "",
        // 岗位类型
        jobTypeId: "",
        // 意向企业
        enterpriseId: undefined,
        // 投递开始时间
        deliveryStartDate: "",
        // 投递结束时间
        deliveryEndDate: "",
        // 简历发送状态
        deliveryStatus: "",
        // 岗位所在项目
        projectIds: [],
      },
      // 岗位类型
      jobTypeList: [],
      // 意向岗位地址
      projectList: [],
      // 简历发送状态
      status: [
        {
          value: 0,
          label: "发送失败",
        },
        {
          value: 1,
          label: "发送成功",
        },
      ],
      // 意向企业
      allEnterprise: [],
      table: {
        columns: [
          // {
          //   title: "简历来源",
          //   dataIndex: "deliverySource",
          //   key: "deliverySource",
          //   // width: 150,
          //   customRender(text) {
          //     return text === 1 ? "企业招聘" : "招聘专场";
          //   },
          // },
          {
            title: "招聘岗位名称",
            dataIndex: "recruitName",
            key: "recruitName",
            // width: 150,
          },
          {
            title: "投递人姓名",
            dataIndex: "deliveryUserName",
            key: "deliveryUserName",
          },
          {
            title: "投递人手机号",
            dataIndex: "deliveryUserPhone",
            key: "deliveryUserPhone",
            // width: 150,
          },
          {
            title: "投递人邮箱",
            dataIndex: "deliveryUserEmail",
            key: "deliveryUserEmail",
            // width: 150,
          },
          {
            title: "岗位类型",
            dataIndex: "deliveryJobTypeName",
            key: "deliveryJobTypeName",
            // width: 160,
          },
          {
            title: "投递企业",
            dataIndex: "enterpriseName",
            key: "enterpriseName",
            // width: 150,
          },
          {
            title: "企业邮箱",
            dataIndex: "enterpriseEmail",
            key: "enterpriseEmail",
            // width: 150,
          },
          {
            title: "岗位所在项目",
            dataIndex: "projectName",
            key: "projectName",
            // width: 180,
          },

          {
            title: "简历投递时间",
            dataIndex: "deliveryDate",
            key: "deliveryDate",
            // width: 180,
            customRender(text) {
              return text ? text : "-";
            },
          },

          {
            title: "简历发送状态",
            dataIndex: "deliveryStatus",
            key: "deliveryStatus",
            // width: 120,
            customRender(text) {
              return text === 1 ? "发送成功" : "发送失败";
            },
          },
          {
            title: "操作",
            key: "action",
            width: "250px",
            fixed: "right",
            scopedSlots: { customRender: "action" },
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
      // 最早时间
      deliveryStartDate: "",
      isLoading: false,
      // 投递时间
      deliveryDate: [],
    };
  },
  computed: {
    // 默认选中
    selectData: {
      get: function () {
        return this.searchForm.enterpriseId;
      },
      set: function (val) {},
    },
  },
  mounted() {
    // 获取岗位类型
    this.getSelectList();
    // 获取意向企业地址
    this.getAllProject();
    // 获取最早投递时间
    this.getDeliveryStartDate();
    // 获取列表数据
    this.getList();
  },
  methods: {
    // 获取最早投递时间
    async getDeliveryStartDate() {
      try {
        const res = await api.getDeliveryDate();
        if (res.code === "200") {
          this.deliveryStartDate = res.data;
        }
      } catch (error) {}
    },
    // 简历投递时间限制
    disabledDate(current) {
      //明天的时间
      let currentDate = new Date();
      currentDate.setTime(currentDate.getTime() + 24 * 60 * 60 * 1000);
      let endDate = currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate();
      let deliveryDate = moment(this.deliveryStartDate).format("YYYY-MM-DD");
      // 最早可选择投递列表投递时间最早的日期到今日
      return !moment(current).isBetween(deliveryDate, endDate);
    },
    // 选择时间处理
    onChangeTime(date, dateString) {
      this.searchForm.deliveryStartDate = dateString[0];
      this.searchForm.deliveryEndDate = dateString[1];
    },
    // 查询
    search() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 投递人姓名
        deliveryUserName: "",
        // 投递人手机号
        deliveryUserPhone: "",
        // 岗位类型
        jobTypeId: "",
        // 意向企业
        enterpriseId: undefined,
        // 投递开始时间
        deliveryStartDate: "",
        // 投递结束时间
        deliveryEndDate: "",
        // 简历发送状态
        deliveryStatus: "",
        // 岗位所在项目
        projectIds: [],
      };
      this.deliveryDate = [];
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 获取数据
    async getList() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
        };
        data.deliveryEndDate = data.deliveryEndDate ? data.deliveryEndDate + "23:59:59" : "";
        data.projectIds = data.projectIds?.join(",");
        const res = await api.resumeList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 重新发送
    async sendAgain(id) {
      this.isLoading = true;
      try {
        const res = await api.resumeResend({ id });
        if (res.code === "200") {
          this.$message.success("发送成功");
          this.getList();
        } else {
          this.$message.error("发送失败");
        }
      } catch (error) {
        this.$message.error("发送失败");
      }
      this.isLoading = false;
    },

    // 查看简历
    toDetail(val) {
      if (val.resumerFileSuffix === "doc" || val.resumerFileSuffix === "docx") {
        let docUrl = val.resumerFile;
        let url = encodeURIComponent(docUrl);
        // let newUrl = "http://view.officeapps.live.com/op/view.aspx?src=" + url;
        // let newUrl = "http://view.xdocin.com/xdoc?_xdoc=" + url;
        let newUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + url;
        window.open(newUrl, "_blank");
      } else {
        window.open(val.resumerFile);
      }
    },
    // 下载
    downloadCommon(res) {
      if ("msSaveOrOpenBlob" in navigator) {
        const url = res.data;
        const blob = new Blob([url], { type: "application/vnd.ms-excel" });
        let name = res.headers["content-disposition"].split("=")[1];
        window.navigator.msSaveOrOpenBlob(blob, decodeURI(name));
        return;
      } else {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let name = res.headers["content-disposition"].split("=")[1];
        link.setAttribute("download", decodeURI(name));
        document.body.appendChild(link);
        link.click();
      }
    },
    // 下载简历
    async download(id) {
      try {
        const res = await api.downLoadResume({ id });
        this.downloadCommon(res);
      } catch (error) {
        console.log(error);
        this.$message.error("下载模板失败");
      }
    },
    // 批量下载
    async batchDownload() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
        };
        data.deliveryEndDate = data.deliveryEndDate ? data.deliveryEndDate + "23:59:59" : "";
        data.projectIds = data.projectIds?.join(",");
        const res = await api.batchDownloadResume(data);
        // 下载
        this.downloadCommon(res);
      } catch (error) {
        this.$message.error("下载失败");
      }
    },
    // 列表导出
    async exportList() {
      try {
        const data = {
          ...this.searchForm,
        };
        data.deliveryEndDate = data.deliveryEndDate ? data.deliveryEndDate + "23:59:59" : "";
        data.projectIds = data.projectIds?.join(",");
        const res = await api.batchExportResume(data);
        // 下载
        this.downloadCommon(res);
      } catch (error) {
        this.$message.error("下载失败");
      }
    },
    //选择企业
    selectEnterPrise(value) {
      this.searchForm.enterpriseId = value;
    },
    handleSearch(value) {
      if (value.trim().length > 0) {
        // 输入时 根据value 远程拉去 数据
        this.getEnterprise(value.trim());
      }
    },
    getEnterprise: debounce(async function (keyWord) {
      try {
        // 企业搜索优化
        const data = {
          enterpriseName: keyWord,
          page: 0,
          size: 100,
        };
        const result = await api.resumeFindEnterprise(data);
        this.allEnterprise = result.data.enterpriseList;
      } catch (err) {
        console.error(err);
      }
    }, 500),
    // 鼠标移入获取清空按钮
    handleClear(e) {
      // 获取到dom元素
      let clearDom = e.path[0].children[0].children[1];
      // 添加点击事件
      clearDom.addEventListener("click", () => {
        // 清空操作
        this.searchForm.enterpriseId = undefined;
      });
    },
    // 获取岗位类型
    async getSelectList() {
      try {
        const res = await api.recruitmentSelectList();
        if (res.code === "200") {
          this.jobTypeList = res.data.jobTypeList;
        }
      } catch (error) {}
    },

    // 获取意向企业地址
    async getAllProject() {
      const data = {
        page: 1,
        size: 999,
        tenant_id: "1",
      };
      // const res = await getProject(data);
      const res = await api.getShunyiAllProject({check_type:true});
      if (res.code === "200") {
        this.projectList = res.data;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
</style>